.about{
  padding-top: 15vh;
  display: flex;
  justify-content: center;
}

.aboutContainer{
  max-width: 60%;
}

.aboutBox{
  padding: 50px;
  border: #630ac2 1px solid;
  border-radius: 20px;
  position: relative;
  z-index: 2;
  background: #13111a; 
}

.about h1 {
  text-align: center;
  font-size: 36px;
  font-weight: 700;
  display: block;
}

.aboutHead2 {
  text-align: center;
  font-size: 36px;
  font-weight: 700;
  margin-top: 30px;
  display: block;
}

.aboutText{
  font-size: 20px;
  text-align: justify;
}

.aboutOrgContainer{
  display: flex;
  justify-content: center;
  align-items: center;
}

.aboutOrgs{
  font-size: 20px;
}

.aboutOrgBox{
  margin-top: 20px;
  padding: 0 20px;
  width: 50%;
}

.bg-gradient-blur-wrapper-about {
  max-width: 70%;
  height: 40%;
  opacity: .5;
  filter: blur(100px);
  position: absolute;
  top: 25%;
  left: 15%;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.bg-gradient-blur-circle-1-about {
  width: 70%;
  max-height: 70%;
  min-height: 70%;
  min-width: 70%;
  background-color: #7a06f9;
  border-radius: 1000000px;
  position: absolute;
}

.bg-gradient-blur-circle-2-about {
  width: 70%;
  max-height: 70%;
  min-height: 70%;
  min-width: 70%;
  background-color: #630ac2;
  border-radius: 1000000px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.bg-gradient-blur-circle-3-about {
  width: 70%;
  max-height: 70%;
  min-height: 70%;
  min-width: 70%;
  background-color: #630ac2;
  border-radius: 1000000px;
  position: absolute;
  top: 0;
  right: 0;
}

@media screen and (max-width: 960px) {
  .about h1 {
    font-size: 42px;
  }

  .aboutContainer{
    max-width: 95%;
  }
  
.bg-gradient-blur-wrapper-about {
  max-width: 70%;
  height: 80%;
  opacity: .5;
  filter: blur(100px);
  position: absolute;
  top: 25%;
  left: 15%;
  right: 0;
  bottom: 0;
  z-index: 0;
}
}

