.footer-section {
  min-height: 150px;
  /* background: linear-gradient(312deg, rgba(55,3,122,1) 0%, rgba(37,6,79,1) 100%); */
  background: #13111a; 
  border-top: 1px solid #fff;
  display: flex;
  justify-content: center;
}

.footerContainer{
  max-width: 60%;
  padding: 20px 0;
}

.footer-section h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 36px;
  padding: 10px 0;
  margin: 0px;
  text-align: center;
  color: #fff;
}

.footerSocials{
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.footerSocialIcons{
  text-decoration: none;
  color: #fff;
  font-size: 24px;
  margin: 0 10px;
}

.footerSocialIcons:hover{
  color: #face1d;
}

.footerText{
  text-align: center;
  font-size: 18px;
  margin: 0;
}