.homeContainer{
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-bottom: 0.1px solid #acacac;
}

.bg-gradient-blur-wrapper-left {
    width: 100%;
    max-width: 30%;
    min-height: 50%;
    opacity: .5;
    filter: blur(100px);
    position: absolute;
    top: 0px;
    left: -20px;
}

.bg-gradient-blur-circle-1-left {
    width: 70%;
    max-height: 70%;
    min-height: 70%;
    min-width: 70%;
    background-color: #7a06f9;
    border-radius: 1000000px;
    position: absolute;
}

.bg-gradient-blur-circle-2-left {
    width: 70%;
    max-height: 70%;
    min-height: 70%;
    min-width: 70%;
    background-color: #630ac2;
    border-radius: 1000000px;
    position: absolute;
    bottom: 0;
    right: 0;
}

.bg-gradient-blur-circle-3-left {
    width: 70%;
    max-height: 70%;
    min-height: 70%;
    min-width: 70%;
    background-color: #630ac2;
    border-radius: 1000000px;
    position: absolute;
    top: 0;
    right: 0;
}

.bg-gradient-blur-wrapper-right {
    width: 100%;
    max-width: 30%;
    min-height: 50%;
    opacity: .5;
    filter: blur(100px);
    position: absolute;
    bottom: 20px;
    right: 0px;
}

.bg-gradient-blur-circle-1-right {
    width: 70%;
    max-height: 70%;
    min-height: 70%;
    min-width: 70%;
    background-color: #7a06f9;
    border-radius: 1000000px;
    position: absolute;
}

.bg-gradient-blur-circle-2-right {
    width: 70%;
    max-height: 70%;
    min-height: 70%;
    min-width: 70%;
    background-color: #fedf31;
    border-radius: 1000000px;
    position: absolute;
    bottom: 0;
    right: 0;
}

.bg-gradient-blur-circle-3-right {
    width: 70%;
    max-height: 70%;
    min-height: 70%;
    min-width: 70%;
    background-color: #630ac2;
    border-radius: 1000000px;
    position: absolute;
    top: 0;
    right: 0;
}

.eeLogo{
    width: 300px;
	margin-top: 15vh;
}

.homeHead{
	font-size: 92px;
	color: #fff;
	font-family: 'Ysabeau SC', sans-serif;
	font-weight: 700;
	margin: 0;
	margin-top: 5vh;
    text-align: center;
}

.homeHeadTag{
	font-size: 56px;
	color: #fff;
	font-family: 'Alex Brush', cursive;
	font-weight: 700;
	margin-bottom: 20px;
}

.homeSubHead{
	font-size: 26px;
	color: #fff;
	margin: 0;
}

.registrationContainer{
	margin-top: 3vh;
	height: 120px;
}

.registraionHead{
	color: #fff;
}

.homeContainer2{
	/* background-color: #fff; */
	min-height: 100vh;
    padding: 10vh 0;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
    flex-direction: column;
	border-bottom: 0.1px solid #acacac;
    overflow: hidden;
}

.infoContainer{
	max-width: 60%;
	display: flex;
	justify-content: center;
	align-items: center;
    flex-direction: column;
}

.imgCol{
	display: flex;
	/* justify-content: end; */
}

.infoCol{
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.infoHead1{
	font-size: 46px;
    font-family: 'Ysabeau SC', sans-serif;
	color: #fff;
	margin: 0;
}

.homeContainer3{
	/* background-color: #fff; */
	height: 100vh;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	/* border-bottom: 0.1px solid #acacac; */
}

.infoHead2{
	font-size: 42px;
}

.homeHeadText{
    font-size: 20px;
    text-align: justify;
}

.speakersContainer {
    max-width: 60%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative; /* Add this property */
    z-index: 1; /* Add this property */
}

.speakersBox{
    padding: 50px;
    border: #630ac2 1px solid;
    border-radius: 20px;
    position: relative;
    z-index: 2;
    background: #13111a; 
}

.bg-gradient-blur-wrapper-speaker {
    max-width: 100%;
    height: 60%;
    opacity: .5;
    filter: blur(100px);
    position: absolute;
    top: 20%;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.bg-gradient-blur-circle-1-speaker {
    width: 70%;
    max-height: 70%;
    min-height: 70%;
    min-width: 70%;
    background-color: #7a06f9;
    border-radius: 1000000px;
    position: absolute;
}

.bg-gradient-blur-circle-2-speaker {
    width: 70%;
    max-height: 70%;
    min-height: 70%;
    min-width: 70%;
    background-color: #630ac2;
    border-radius: 1000000px;
    position: absolute;
    bottom: 0;
    right: 0;
}

.bg-gradient-blur-circle-3-speaker {
    width: 70%;
    max-height: 70%;
    min-height: 70%;
    min-width: 70%;
    background-color: #630ac2;
    border-radius: 1000000px;
    position: absolute;
    top: 0;
    right: 0;
}


.bg-gradient-blur-wrapper-center-right {
    width: 100%;
    max-width: 30%;
    min-height: 50%;
    opacity: .5;
    filter: blur(100px);
    position: absolute;
    top: 130vh;
    right: 0px;
    overflow: hidden;
    z-index: 1;
}

.bg-gradient-blur-wrapper-center-left {
    width: 100%;
    max-width: 30%;
    min-height: 50%;
    opacity: .5;
    filter: blur(100px);
    position: absolute;
    top: 180vh;
    left: 0px;
    z-index: 1;
}

.bg-gradient-blur-circle-1-center-left {
    width: 70%;
    max-height: 70%;
    min-height: 70%;
    min-width: 70%;
    background-color: #7a06f9;
    border-radius: 1000000px;
    position: absolute;
}

.bg-gradient-blur-circle-2-center-left {
    width: 70%;
    max-height: 70%;
    min-height: 70%;
    min-width: 70%;
    background-color: #fedf31;
    border-radius: 1000000px;
    position: absolute;
    bottom: 0;
    right: 0;
}

.bg-gradient-blur-circle-3-center-left {
    width: 70%;
    max-height: 70%;
    min-height: 70%;
    min-width: 70%;
    background-color: #630ac2;
    border-radius: 1000000px;
    position: absolute;
    top: 0;
    right: 0;
}


.homeText2{
    font-size: 19px;
    margin: 0;
}

.homeImage1{
    height: 100%;
    position: relative;
    z-index: 10;
}

.imgCol2{
    display: flex;
    justify-content: end;
}

.homeImage2{
    /* width: 400px; */
    height: 70%;
    position: relative;
    z-index: 10;
}

.homeBSubHead{
    text-align: center;
    margin-top: 30px;

}

@media screen and (max-width: 960px) {
.homeHead{
	font-size: 32px;
	color: #fff;
	font-family: 'Ysabeau SC', sans-serif;
	font-weight: 700;
	margin: 0;
}

.homeHeadTag{
	font-size: 26px;
	color: #fff;
	font-family: 'Alex Brush', cursive;
	font-weight: 700;
	margin-bottom: 20px;
}

.homeSubHead{
	font-size: 20px;
	color: #fff;
	margin: 0;
}

.registrationContainer{
	bottom: 0;
	height: 100px;
}

.speakersBox{
    padding: 10px;
}

.imgCol, .imgCol2{
    align-items: center;
    justify-content: center;
}

.infoContainer{
	max-width: 90%;
}

.homeText2{
    font-size: 16px;
}

.infoHead1, .amtTag{
    text-align: center;
}

.btnContainer{
    text-align: center;
}


/* .homeImage{
    width: 300px;
} */

}