.thankYou{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 15vh;
}

.thankYouContainer{
    max-width: 70%;
    position: relative;
    z-index: 1;
}


.tableBody td {
    font-size: 18px;
    font-weight: 400;
    color: #face1d;
    background-color: #13111a;
}

.qrImg{
    width: 100%;
}

@media screen and (max-width: 960px) {
    .thankYouContainer{
      max-width: 95%;
    }

    .tableBody td {
        font-size: 18px;
        font-weight: 400;
        color: #face1d;
        background-color: #13111a;
    }

    .qrImg{
        width: 95%;
    }
}