.signup{
    padding-top: 15vh;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.signupContainer{
    width: 100%;
}

.signupForm{
    background-color: #13111aab;
    border: #630ac2 1px solid;
    border-radius: 20px;
    padding: 20px;
    height: 100%;
}

.signupRight{
    height: 80vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.signupRightContainer{
    display: flex;
    height: 40vh;
    width: 100%;
    color: #000;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #e9d520da;
}


.signupLeft{
    height: 80vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.signupLeftContainer{
    display: flex;
    height: 40vh;
    width: 100%;
    color: #000;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #e9d520da;
}

.signUpHead1{
    font-size: 42px;
    font-weight: 800;
	font-family: 'Ysabeau SC', sans-serif;
    margin: 0;
}

.signUpHead2{
    font-size: 36px;
    font-weight: 600;
	font-family: 'Alex Brush', cursive;
    margin: 0;
}

.signUpHead3{
    font-size: 26px;
    font-weight: 400;
	font-family: 'Ysabeau SC', sans-serif;
    margin: 0;
}

.loginLink{
    margin: 0;
    text-align: left;
    color: #fff;
    text-decoration: none !important;
}

.loginLink:hover{
    text-decoration: underline !important;
    transition: 200ms;
    color: rgba(246,255,35,1);
    cursor: pointer;
}

@media screen and (max-width: 960px) {
    .signUpFormContainer{
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 10vh;
    }

    
.signupForm{
    background-color: #13111aab;
    border: #630ac2 1px solid;
    border-radius: 20px;
    padding: 20px;
    height: 100%;
        width: 80%;
}

    
.signupLeft{
    height: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.signupLeftContainer{
    display: flex;
    height: 40vh;
    width: 100%;
    color: #000;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #e9d520da;
}

    .signupRight{
        height: 20vh;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
    
    .signupRightCntainer{
        display: flex;
        height: 40vh;
        width: 100%;
        color: #000;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background-color: #e9d520da;
    }

    
.signUpHead1{
    font-size: 36px;
}

.signUpHead2{
    font-size: 30px;
}

.signUpHead3{
    font-size: 16px;
}

}