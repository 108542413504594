.eeRegister{
    width: 100%;
    padding-top: 25vh;
    padding-bottom: 10vh;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.eeFormContainer{
    width: 70%;
    background-color: #13111aab;
    border-radius: 30px;
    border: #630ac2 1px solid;
    padding: 30px;
    backdrop-filter: blur(1px);
    z-index: 1;
}


.bg-gradient-blur-wrapper-left-form {
    width: 100%;
    max-width: 50%;
    min-height: 70%;
    opacity: .5;
    filter: blur(100px);
    position: absolute;
    top: 20px;
    left: -20px;
}

.bg-gradient-blur-wrapper-left-form2 {
    width: 100%;
    max-width: 30%;
    min-height: 50%;
    opacity: .5;
    filter: blur(100px);
    position: absolute;
    top: 110%;
    left: -20px;
}

.bg-gradient-blur-circle-1-left {
    width: 70%;
    max-height: 70%;
    min-height: 70%;
    min-width: 70%;
    background-color: #7a06f9;
    border-radius: 1000000px;
    position: absolute;
}

.bg-gradient-blur-circle-2-left {
    width: 70%;
    max-height: 70%;
    min-height: 70%;
    min-width: 70%;
    background-color: #630ac2;
    border-radius: 1000000px;
    position: absolute;
    bottom: 0;
    right: 0;
}

.bg-gradient-blur-circle-3-left {
    width: 70%;
    max-height: 70%;
    min-height: 70%;
    min-width: 70%;
    background-color: #630ac2;
    border-radius: 1000000px;
    position: absolute;
    top: 0;
    right: 0;
}

.bg-gradient-blur-wrapper-right-form {
    width: 100%;
    max-width: 50%;
    min-height: 70%;
    opacity: .5;
    filter: blur(100px);
    position: absolute;
    bottom: 20px;
    right: 0px;
}

.bg-gradient-blur-wrapper-right-form2 {
    width: 100%;
    max-width: 30%;
    min-height: 50%;
    opacity: .5;
    filter: blur(100px);
    position: absolute;
    top: 150%;
    right: 0px;
}

.bg-gradient-blur-circle-1-right {
    width: 70%;
    max-height: 70%;
    min-height: 70%;
    min-width: 70%;
    background-color: #7a06f9;
    border-radius: 1000000px;
    position: absolute;
}

.bg-gradient-blur-circle-2-right {
    width: 70%;
    max-height: 70%;
    min-height: 70%;
    min-width: 70%;
    background-color: #fedf31;
    border-radius: 1000000px;
    position: absolute;
    bottom: 0;
    right: 0;
}

.bg-gradient-blur-circle-3-right {
    width: 70%;
    max-height: 70%;
    min-height: 70%;
    min-width: 70%;
    background-color: #630ac2;
    border-radius: 1000000px;
    position: absolute;
    top: 0;
    right: 0;
}


@media screen and (max-width: 960px) {
    
.eeFormContainer{
    width: 90%;
    padding: 20px;
    z-index: 1;
}
}